import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {DataPackageStatusEnum, DataPackageValidationModeEnum} from '@vivli/features/studies/infrastructure/enum';
import {UploaderComponent} from './uploader/uploader.component';
import {ButtonComponent, FieldHeaderComponent, LoadIndicatorComponent} from '@vivli/shared/components';
import {useActiveUser} from '@vivli/core/infrastructure/context';
import {IDataPackage, IDataPackageFileDescriptor} from '@vivli/shared/features/data-package/infrastructure/interface';
import {Size, Styles} from '@vivli/shared/theme';
import sanitize from 'sanitize-filename';
import {useModalService, useToastService} from '@vivli/shared/infrastructure/context';
import {DataPackageValidationMessageComponent} from './data-package-validation-message.component';
import {DataPackageFileComponent} from './data-package-file.component';
import moment from 'moment';
import {useDataPackageService} from '@vivli/shared/features/data-package/infrastructure/context';
import {concatMap, first, map} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DataPackageValidateButtonComponent} from './data-package-validate-button.component';
import {useVivliConfig} from '@vivli/core/infrastructure/hook';
import {IFileExtension, IVivliConfiguration} from '@vivli/shared/infrastructure/interface';
import {FileUploadWarningComponent} from './file-upload-warning.component';
import {DTIAMRCommonConst,} from "@vivli/shared/infrastructure/constants";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {useOrganizationsService} from "@vivli/features/organizations/infrastructure/context";
import {FileUploadWarningChatComponent} from "./file-upload-warning-chat.component";
import {useUserPermissions} from "@vivli/features/users/infrastructure/hook";

const containerStyle: CSSProperties = {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
};

const noFilesStyle: CSSProperties = {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
};

const loadingContainerStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
};

const loadingStyle: CSSProperties = {
    ...Styles.CENTERED_IN_BLOCK,
    zIndex: 100,
    minWidth: 'initial',
};

const dataPackageContainerStyle: CSSProperties = {
    position: 'relative',
    paddingBottom: Size.PADDING,
    paddingTop: 0,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    maxWidth: '100%',
};

const filesContainerStyle = (hasFiles: boolean, scroll = true): CSSProperties => ({
    overflow: scroll ? 'auto' : 'unset',
    background: '#E9EAEF',
    padding: hasFiles ? '15px' : 0,
    paddingBottom: hasFiles ? '5px' : 0,
});

const buttonStyle: CSSProperties = {...Styles.Button, height: 40, marginTop: 20, marginRight: 20};

const buttonStyleClose: CSSProperties = {...Styles.Button, height: 40, margin: `20px auto 0`, width: 150};

const titleStyle: CSSProperties = {marginLeft: 20, marginTop: 20};

//const sub = dataPackageService.getDataPackageSubmitStatus(dataPackageId).pipe(first()).subscribe(setDataPackageStatus);
interface DataPackageFeatureProps {
    dataPackage: IDataPackage;
    validationMode?: DataPackageValidationModeEnum;
    availableTypes?: string[];
    requiredTypes?: string[];
    allowUpload?: boolean;
    allowSubmit?: boolean;
    readOnly?: boolean;
    hideFileType?: boolean;
    publiclyAvailableOrgId?: string;
    showDateTime?: boolean;
    useSecureStorage?: boolean;
    recordFileDownload?: boolean;
    allowDownload?: boolean;
    disableSubmit?: boolean;
    allowDelete?: boolean;
    isSubmittingFiles?: boolean;
    onFileRemoved?: (file: IDataPackageFileDescriptor) => void;
    onFileAdded?: (file: IDataPackageFileDescriptor) => void;
    onFileAdding?: (file: IDataPackageFileDescriptor) => void;
    onPackageSubmitted?: (dataPackage: IDataPackage) => void;
    onValidation?: (valid: boolean) => void;
    onReady?: () => void;
    setUploadFileName?: React.Dispatch<React.SetStateAction<string>>
    miniature?: boolean;
    datasetMetaDataDoi?: string;
    studyOrgId?: string;
    showChatUploadFileWarning?: boolean;
    showVerifyButton?: boolean;
    sortFilesByDate?: boolean; //default is sort by name
    expandFilenames?: boolean; //default is don't expand
    onClose?: () => void;
    scrollEnabled?: boolean;
    showFileWarning?: boolean;
    organization?: IOrganization;
    setOrganization?: React.Dispatch<React.SetStateAction<IOrganization>>;
    showDeleteDialog?: boolean;
}

export const DataPackageComponent = ({
                                         dataPackage,
                                         requiredTypes,
                                         availableTypes,
                                         validationMode = DataPackageValidationModeEnum.All,
                                         allowUpload,
                                         allowSubmit,
                                         onFileRemoved,
                                         setUploadFileName,
                                         onFileAdded,
                                         onFileAdding,
                                         readOnly,
                                         hideFileType,
                                         publiclyAvailableOrgId,
                                         showDateTime,
                                         useSecureStorage = true,
                                         showChatUploadFileWarning = false,
                                         recordFileDownload,
                                         allowDownload,
                                         onPackageSubmitted,
                                         disableSubmit,
                                         isSubmittingFiles,
                                         allowDelete,
                                         onValidation,
                                         onReady,
                                         miniature,
                                         showVerifyButton = true,
                                         datasetMetaDataDoi,
                                         studyOrgId,
                                         sortFilesByDate,
                                         expandFilenames,
                                         onClose,
                                         scrollEnabled = true,
                                         showFileWarning,
                                         organization,
                                         setOrganization,
                                         showDeleteDialog
                                     }: DataPackageFeatureProps) => {
    const filesInQueueRef = useRef(0);
    const filesQueueRef = useRef(new Subject<IDataPackageFileDescriptor>());
    const dataPackageRef = useRef(dataPackage);
    const filesRef = useRef<IDataPackageFileDescriptor[]>(null);
    const vivliConfigRef = useRef<IVivliConfiguration>(null);
    const [activeDataPackage, setActiveDataPackage] = useState(dataPackage);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [files, setFiles] = useState<IDataPackageFileDescriptor[]>(null);
    const [hasRequiredTypes, setHasRequiredTypes] = useState(false);
    const orgsService = useOrganizationsService();
    const initializedRef = useRef(false);

    const user = useActiveUser();
    const {isOrgAdmin} = useUserPermissions();
    const modalService = useModalService();
    const dataPackageService = useDataPackageService();
    const toast = useToastService();
    const vivliConfig = useVivliConfig();

    const isDraft = activeDataPackage?.status === DataPackageStatusEnum.Draft;
    const isSortByDate: boolean = sortFilesByDate !== undefined && sortFilesByDate;

    const updateActiveDataPackage = (dataPackage: IDataPackage) => {
        dataPackageRef.current = dataPackage;
        setActiveDataPackage(dataPackage);
    };

    const validateRequiredTypes = () => {
        if (!requiredTypes) {
            return true;
        }

        switch (validationMode) {
            case DataPackageValidationModeEnum.All:
                return requiredTypes.every((type) => filesRef.current.some((file) => file.type === type));
            case DataPackageValidationModeEnum.Any:
                return requiredTypes.some((type) => filesRef.current.some((file) => file.type === type));
            default:
                return true;
        }
    };

    const handleFilesUpdate = (updatedFiles: IDataPackageFileDescriptor[]) => {
        if (!isSortByDate) {
            filesRef.current = updatedFiles;
            setFiles([...updatedFiles]);
        } else {
            const dateSortedFiles = updatedFiles.sort((a, b) => moment(b.updatedDateTime).valueOf() - moment(a.updatedDateTime).valueOf());
            filesRef.current = dateSortedFiles;
            setFiles([...dateSortedFiles]);
        }
    };

    const updateFileInPlace = (updatedFile: IDataPackageFileDescriptor) => {
        const updatedFiles = filesRef.current.map((file) => {
            if (file.id === updatedFile.id) {
                return updatedFile;
            }

            return file;
        });

        filesInQueueRef.current = filesInQueueRef.current - 1;

        handleFilesUpdate(updatedFiles);
    };

    const handleFileTypeChanged = (updatedFile: IDataPackageFileDescriptor) => {
        updateFileInPlace(updatedFile);
    };

    const handleFileDeleted = (file: IDataPackageFileDescriptor, dataPackage: IDataPackage) => {
        const updatedFiles = [...filesRef.current.filter((f) => f.id !== file.id)];

        if (dataPackage) {
            updateActiveDataPackage(dataPackage);
        }
        handleFilesUpdate(updatedFiles);
        onFileRemoved && onFileRemoved(file);
    };

    const handleFileValidation = (id: number, name: string): Promise<boolean> => {
        const preExistingFile = filesRef.current.find((f) => f.name === name);
        const extension = '.' + name.split('.')[1];

        const warningExistingFile = vivliConfigRef.current.fileExtensions.some((file: IFileExtension) => {
            return extension === file.extension && file.warnForDocumentUpload === true;
        });

        if (warningExistingFile && showFileWarning) {
            return new Promise((resolve, reject) => {
                const modalId = modalService.custom(
                    showChatUploadFileWarning ?
                        <FileUploadWarningChatComponent
                            handleConfirm={() => {
                                modalService.dismiss(modalId);
                                resolve(true);
                            }}
                            handleCancel={() => {
                                modalService.dismiss(modalId);
                                reject(false);
                            }}
                        />
                        :
                        <FileUploadWarningComponent
                            handleConfirm={() => {
                                modalService.dismiss(modalId);
                                resolve(true);
                            }}
                            handleCancel={() => {
                                modalService.dismiss(modalId);
                                reject(false);
                            }}
                        />
                );
            });
        }

        if (preExistingFile) {
            return new Promise((resolve, reject) => {
                modalService.confirm(`A file named ${name} already exists.  Click OK to overwrite.`, {
                    confirmText: 'OK',
                    cancelText: 'Cancel',
                    onConfirm: () => resolve(true),
                    onCancel: () => reject(false),
                });
            });
        }
    };

    const handleFileUploadStart = (uploaderId: number, name: string, sizeInKb: number) => {
        const preExistingFile = filesRef.current.find((f) => f.name === name);
        const fileName = sanitize(name, {replacement: '_'});

        if (!preExistingFile) {
            const fileToAdd = {
                name: fileName,
                type: 'Unknown',
                percentUploaded: 0,
                isComplete: false,
                isError: false,
                uploadedByUserEmail: user.email,
                uploadedByUserId: user.userId,
                uploadedByUserName: user.name,
                sizeInKb,
                id: uuidv4(),
                uploaderId,
            };

            const updatedFiles = [...filesRef.current, fileToAdd];
            handleFilesUpdate(updatedFiles);
            onFileAdding && onFileAdding(fileToAdd);
        } else {
            const fileToReUpload = {
                ...preExistingFile,
                percentUploaded: 0,
                isComplete: false,
                isError: false,
                uploadedByUserEmail: user.email,
                uploadedByUserId: user.userId,
                uploadedByUserName: user.name,
                sizeInKb,
                id: uuidv4(),
                uploaderId,
            };

            const updatedFiles = [...filesRef.current.filter((f) => f.name !== preExistingFile.name), fileToReUpload];
            handleFilesUpdate(updatedFiles);
        }

        setIsSubmitDisabled(true);
    };

    const handleFileUploadProgress = (id: number, name: string, percentUploaded: number) => {
        const existingFile = filesRef.current.find((f) => f.uploaderId === id);
        if (!existingFile) {
            return;
        }

        const updatedFile: IDataPackageFileDescriptor = {
            ...existingFile,
            percentUploaded,
        };

        updateFileInPlace(updatedFile);
    };

    const handleFileUploadCompleted = (id: number, name: string) => {
        const existingFile = filesRef.current.find((f) => f.uploaderId === id);
        if (!existingFile) {
            return;
        }

        const updatedFile: IDataPackageFileDescriptor = {
            ...existingFile,
            percentUploaded: 100,
            isComplete: true,
        };

        filesQueueRef.current.next(updatedFile);
        updateFileInPlace(updatedFile);
        onFileAdded && onFileAdded(updatedFile);
    };

    const handleFileUploadError = (id: number, name: string, error?: string) => {
        const existingFile = filesRef.current.find((f) => f.uploaderId === id);
        if (!existingFile) {
            return;
        }

        const updatedFile: IDataPackageFileDescriptor = {
            ...existingFile,
            percentUploaded: 0,
            isError: true,
        };

        error && modalService.message(error);

        //delete file from data package if there is an upload error
        handleFileDeleted(updatedFile, dataPackage);
    };

    const handleAllFilesCompleted = async (succeededFileIds: number[], failedFileIds: number[]) => {
        if (filesInQueueRef.current <= 0) {
            setIsSubmitDisabled(false);
        } else {
            setTimeout(() => {
                handleAllFilesCompleted(succeededFileIds, failedFileIds);
            }, 1000);
        }
    };

    const onSubmitFiles = () => {
        //update current file descriptions
        dataPackageRef.current.dataPackageFileDescriptors = [...files];
        onPackageSubmitted && onPackageSubmitted(dataPackageRef.current);
    };

    const handleFileAdd = (file: IDataPackageFileDescriptor) => {
        filesInQueueRef.current = filesInQueueRef.current + 1;
        return dataPackageService.addUpdateFile(dataPackageRef.current.id, file).pipe(
            map((dataPackage) => {
                updateActiveDataPackage(dataPackage);
                return file;
            })
        );
    };

    function handleUploadingError(error) {
        modalService.error(
            `The following files were not successfully uploaded - please notify Vivli at support@vivli.org, and include the study ID and if appropriate, the request number. Message: ${error}`
        );
    }

    function handleError(customMessage: string, error) {
        modalService.error(
            `${customMessage} Error received: ${error}`
        );
    }

    const handleFileCheck = (value: string) => {
        dataPackageService
            .getDataPackageFileStatus(value)
            .pipe(map((result) => result))
            .subscribe(
                (result) => {
                    toast.info('All data has been successfully uploaded and stored in the system.', {
                        autoClose: 9000,
                    });
                },
                (e) => handleUploadingError(e)
            );
    };
    const getPackageIdForFile = (file: IDataPackageFileDescriptor): string => {
        //in case of study documents blob container name contains Ipd Data Package Id
        return file.publicAccessGranted && (dataPackage.blobContainerName) && dataPackage.blobContainerName != dataPackage.id
            ? dataPackage.blobContainerName
            : dataPackage.id;
    }

    useEffect(() => {
        const fileQueueSub = filesQueueRef.current.pipe(concatMap((file) => handleFileAdd(file))).subscribe(updateFileInPlace);
        return () => {
            fileQueueSub.unsubscribe();
        };

    }, []);

    useEffect(() => {
        if (disableSubmit === undefined) {
            return;
        }

        setIsSubmitDisabled(disableSubmit);
    }, [disableSubmit]);

    useEffect(() => {
        if (!dataPackage) {
            return;
        }

        updateActiveDataPackage(dataPackage);

        // files never have IDs coming back from the server, handle it manually for the list render
        handleFilesUpdate(
            dataPackage.dataPackageFileDescriptors.map((file) => ({
                ...file,
                id: uuidv4(),
            }))
        );
        //get organization if needed
        if (publiclyAvailableOrgId && publiclyAvailableOrgId != 'FakeOrgId' && !readOnly) {
            orgsService.getOrganization(publiclyAvailableOrgId)
                .pipe(first())
                .subscribe(org => {
                    setOrganization(org);
                }, (error) => {
                    handleError('Error getting organization.', error);
                });
        }
    }, [dataPackage]);

    useEffect(() => {
        if (!filesRef.current || !files) {
            return;
        }

        const isValid = validateRequiredTypes();
        setHasRequiredTypes(isValid);

        setUploadFileName && setUploadFileName(files[0]?.name)

        if (!initializedRef.current) {
            initializedRef.current = true;
            onReady && onReady();
        }
    }, [files]);

    useEffect(() => {
        onValidation && onValidation(hasRequiredTypes);
    }, [hasRequiredTypes]);

    useEffect(() => {
        // convert config to ref so that functions fired from internal plugin can still access it
        vivliConfigRef.current = vivliConfig;
    }, [vivliConfig]);

    return (
        <div style={containerStyle}>
            {!activeDataPackage && (
                <div style={noFilesStyle}>
                    <FieldHeaderComponent title={'NO FILES IN PACKAGE'}/>
                </div>
            )}

            {isLoading && (
                <div style={loadingContainerStyle}>
                    <div style={loadingStyle}>
                        <LoadIndicatorComponent/>
                    </div>
                </div>
            )}

            {files && !isLoading && (
                <div style={dataPackageContainerStyle}>
                    {files.length === 0 &&
                        <FieldHeaderComponent title={'NO FILES IN PACKAGE'} style={{alignSelf: 'center'}}/>}

                    {allowUpload && allowSubmit && !hasRequiredTypes && isDraft && (
                        <DataPackageValidationMessageComponent
                            requiredTypes={requiredTypes}
                            files={files}
                            validationMode={validationMode}
                        />
                    )}

                    {isDraft && allowUpload && (
                        <div>
                            <UploaderComponent
                                dataPackageId={dataPackage.id}
                                useSecureStorage={useSecureStorage}
                                onFileUploadStart={handleFileUploadStart}
                                onFileUploadProgress={handleFileUploadProgress}
                                onFileUploadCompleted={handleFileUploadCompleted}
                                onFileUploadError={handleFileUploadError}
                                onAllFilesCompleted={handleAllFilesCompleted}
                                onFileValidation={handleFileValidation}
                            />
                        </div>
                    )}

                    <div style={{width: '100%'}}>
                        <div style={Styles.FORM_ROW}>
                            {files.length > 0 && <FieldHeaderComponent title={'UPLOADED FILES'} style={titleStyle}/>}
                            {files.length > 0 && showVerifyButton && (user.isVivliAdmin || isOrgAdmin) && useSecureStorage &&
                                <DataPackageValidateButtonComponent style={buttonStyle}
                                                                    onClick={() => handleFileCheck(dataPackage.id)}/>}
                        </div>
                    </div>

                    <div className={'scrolly'} style={filesContainerStyle(files.length > 0, scrollEnabled)}>
                        {files.map((file) => (
                            <DataPackageFileComponent
                                key={file.id}
                                dataPackageId={getPackageIdForFile(file)}
                                file={file}
                                availableTypes={availableTypes}
                                readOnly={readOnly || isSubmitDisabled || !isDraft}
                                onFileTypeChanged={handleFileTypeChanged}
                                onFileDeleted={handleFileDeleted}
                                hideFileType={hideFileType}
                                showPubliclyAvailable={!!(publiclyAvailableOrgId)}
                                showDateTime={showDateTime}
                                allowDownload={allowDownload}
                                allowDelete={allowDelete && isDraft}
                                recordDownloadHistory={recordFileDownload}
                                hasRequiredTypes={hasRequiredTypes || !isDraft}
                                requiredTypes={requiredTypes}
                                miniature={miniature}
                                useSecureStorage={useSecureStorage}
                                datasetMetaDataDoi={datasetMetaDataDoi}
                                studyOrgId={studyOrgId}
                                expandedFilenameText={expandFilenames}
                                organization={organization}
                                showDeleteDialog={showDeleteDialog}
                            />
                        ))}
                    </div>

                    {isDraft && allowSubmit && files.length > 0 && (
                        <ButtonComponent
                            onClick={onSubmitFiles}
                            isLoading={isSubmittingFiles}
                            style={buttonStyle}
                            disabled={!hasRequiredTypes || isSubmitDisabled || files.length === 0}
                            className="dataPackage_submit"
                            dataId={DTIAMRCommonConst.SubmitButton}
                        >
                            Submit Files
                        </ButtonComponent>
                    )}

                    {onClose && (
                        <ButtonComponent dataId={DTIAMRCommonConst.OkButton} onClick={onClose} style={buttonStyleClose}>
                            Ok
                        </ButtonComponent>
                    )}
                </div>
            )}
        </div>
    );
};
