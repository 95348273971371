
export const DTISubmitStudyHeader = {
    SubmitStudyWithdraw : 'submitStudy_withdraw',
    SubmitStudySubmit : 'submitStudy_submit',
    SubmitStudySave : 'submitStudy_save',
}

export const DTIConfirmDialogueModal = {
    ConfirmDialogTextInput : 'confirm-dialog-text-input',
    ModalConfirmOk : 'modal_confirm_ok',
}
export const DTIMessageModal = {
    PopupContinueButton : 'PopupContinueButton',
}
export const DTIActiveUserMenu = {
    MenuItemSearch : 'MenuItem_Search',
    MenuItemEditMyProfile : 'MenuItem_EditMyProfile',
    MenuItemChangePassword: 'MenuItem_ChangePassword',
    MenuItemLogOut: 'MenuItem_LogOut',
    MainMenu : 'main_menu'
}
export const DTIEnquiryDiscussionGrid = {
    DiscussionCommentInput : 'discussion-comment-input',
}
export const DTICommonConst = {
    OkButton : 'ok-button',
    CancelButton : 'cancel-button',
    EditButton : 'edit_dr_button',
    ApproveButton : 'data_request_approve_button',
    AwaitingMyAction : 'tabItem_Awaiting_My_Action',
    SoftRejectButton : 'data_request_soft_reject_button',
    ArchiveButton : 'archive_button',
    DraftButton : 'data_request_reset_to_draft_button',
    SaveButton : 'save-button',
    SubmitButton : 'RequestHeaderSubmitButton',
    ResetButton : 'reset_button',
    RequestStudyMenu : 'request_study_menu',
    ExportCsvButton : 'export_csv_button',
    ModalClose : 'modal_close',
    dua_details : 'tabItem_DUA_Details',
    NavigationPanel : 'navigation_panel',
    HeaderApprovalButton: (ButtonText: any) => `${ButtonText}_button`,
}
export const DTIResearchProposalForm = {
    ResearchProposalTitleTextBox : 'ResearchProposalTitleTextBox',
    ResearchProposalSummaryTextBox : 'ResearchProposalSummaryTextBox',
    BriefDescriptionTextBox : 'BriefDescriptionTextBox',
    ResearchProposalStudyDesignTextBox : 'ResearchProposalStudyDesignTextBox',
    ResearchProposalMainPredictorTextBox : 'ResearchProposalMainPredictorTextBox',
    ResearchProposalOtherVariablesTextBox : 'ResearchProposalOtherVariablesTextBox',
    ResearchProposalDisseminationPlanTextBox : 'ResearchProposalDisseminationPlanTextBox',
}
export const DTIStatisticalAnalysisPlanForm = {
    StatisticalAnalysisPlanDescriptionTextBox : 'StatisticalAnalysisPlanDescriptionTextBox',
}
export const DTIDuaValidationButton = {
    DataRequestBeginDuaValidationButton : 'data_request_begin_dua_validation_button'
}
export const DTISignDocument = {
    IAgreeInput : 'i-agree-input',
    SignButton : 'sign-button',
    SignCancelButton : 'sign-cancel-button'
}
export const DTISetTrackingButton = {
    TrackButton : 'track_button',
}
export const DTIVmProvisionedConnect = {
    ConnectToEnvironmentButton : 'connect_to_environment_button',
}
export const DTIAttestationsForm = {
    AttestationCheckBox : 'AttestationCheckBox',
}
export const DTIRejectButton = {
    DataRequestRejectButton : 'data_request_reject_button',
}
export const DTITeamMemberControlsForm = {
    LeadResearcherCheckbox : 'LeadResearcher_checkbox',
}
export const DTIInternalLinkMenu = {
    HeaderFeedbackLink : 'header_feedbackLink,'
}
export const DTILogoComponent = {
    HomePageLogo : 'home_page_logo',
}
export const DTIEsign = {
    ESignButton : 'esign-button',
}
export const DTISearchButton = {
    SearchGo : 'search_go',
}
export const DTIWelcomeModal = {
    WelcomeModalButtonClose : 'WelcomeModal_ButtonClose',
}
export const DTIRequestedStudyRow = {
    StudyRowUploadIpd : 'studyRow_uploadIpd',
    AdminstudyRowMakeIPDAvailable : 'adminstudyRow_makeIPDAvailable',
}
export const DTIEnquiryLink = {
    HeaderEnquiry : 'header_enquiry',
}
export const DTIQuickStudyLookupButton = {
    HeaderQuickStudyLookUp : 'header_quickStudyLookup',
}
export  const DTISignupLoginButton = {
    HeaderSignup : 'header_signup',
    HeaderLogin : 'header_login',
}
export const DTIGrid = {
    AgGrid : 'ag-grid-component',
}
export const DTIMyDataRequestsButton = {
    HeaderMyDataRequest : 'header_myDataRequests',
}
export const DTIStudyDesignFilterGroup = {
    InterventionalOption : 'interventional-option',
    ObservationalOption : 'observational-option',
}
export const DTISearchInput = {
    SearchBox : 'search-box',
}
export const DTIStudyPhaseFilterGroup = {
    StudiesOption : 'studies-option',
}
export const DTITeamMemberRow = {
    AdditionalResearcherAccount : 'additional_researcher_account',
}
export const DTIOrganizationMembershipList = {
    OrgMemberRoles : 'org-member-roles-',
    OrgMemberRolesList : 'org-member-roles-list',
    OrgAdminRole : 'org-admin-role',
    IrpReviewerRole : 'irp-reviewer-role',
    DataContributorRole : 'data-contributor-role',
    CuratorRole : 'curator-role',
}
export const DTIDataRequestStatusRenderer = {
    DataRequestStatus : 'dataRequestStatus_',
}
export const DTIResetToDraftWithTitle = {
    ResetTitle : 'reset_title',
    DataRequest : 'data_request_',
}

export const DTIResearchTeam = {
    RTDFirstName :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.firstName`,
    RTDLastName :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.lastName`,
    RTDOrcidId :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.orcidId`,
    RTDEmail :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.email`,
    RTDPosition :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.position`,
    RTDEmployerOrAffiliation :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.employerOrAffiliation`,
    RTDCountry :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.country` ,
    RTDEducationOrQualifications :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.educationOrQualifications`,
    RTDConflictsOfInterest :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.conflictsOfInterest`,
    RTDAdminApprovalStatus :  (index: number) =>  `researchTeam.researchTeamDetails.${index}.adminApprovalStatus`,
}
export const DTITab = {
    TabItem: (TabStatus: any) => `tabItem_${TabStatus}`,
    DataRequestTabs: (DRTab: any) => `${DRTab}_tab`,
}
export const DTIDataRequest = {
    DataRequestTitle : 'dataRequestTitle_',
    DataRequestStatus : 'dataRequestStatus_',
    DREllipsisMenu: (menu: any) => `${menu}-ellipsis-menu`,
}
export const DTIDropdownMenu = {
    DropdownMenuItem : (DropdownItem: any) => `dropdown_menu_item_${DropdownItem}` ,
}
export const DTIUserMenu = {
    MenuItem: (MenuName: any) => `menuitem_${MenuName}`,
}
export const DTIStaticIds = {
    ResearchProposal : (Proposal: any) => `researchProposal.${Proposal}`,
    TestingCheckbox : 'Training/Testing_checkbox',
    AICheckbox:  'A.I.algorithm_checkbox',
    ResearchTeam: 'research_team',
    ResearchEnvironment : 'research_environment',
    Country: 'country',
    publicDisclosures: 'public_disclosures',
    manual_button : 'ManuallyButton',
    formTitle : 'FormTitle',
    status_update : 'status_update',
    save_button: 'SaveButton',

}
export const DTIRequestedStudiesFeature = {
    AddPlus: 'add_plus',
}
export const DTIAddUnlistedStudyForm = {
    RequestStudies : (RS: any) => `Request_studies_${RS}`,
}
export const DTIChatFeature = {
    Chat: (chat: any) => `chat_${chat}`,
    ChatMessage: (message: any) => `message-${message}`
}
export const DTIOrgMemberRowRender = {
    OrgDetailsCancel: (cancel: any) => `orgDetails_cancel_${cancel}`,
    OrgDetailsActivate: (activate: any) => `orgDetails_activate_${activate}`,
}


