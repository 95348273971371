﻿import React, {CSSProperties, useEffect} from "react";
import {LoggingAreaTableComponent} from "./logging-area-table.component";
import {useAdminConsoleContext} from "@vivli/features/admin-console/infrastructure/context";
import {ButtonComponent, LoadIndicatorCenteredComponent} from "@vivli/shared/components";


const headerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: "space-between",
    width: "50%",
    paddingBottom: "10px"

}
export const LoggingAreasComponent = () => {

    const {
        getLoggingDetails,
        loggingData,
        handleLoggingDropdownChange,
        handleLoggingUpdate,
        isLoading,
        isUpdating
    } = useAdminConsoleContext();


    useEffect(() => {
        getLoggingDetails()
    }, [])


    if (isLoading) {
        return <LoadIndicatorCenteredComponent/>
    }

    return (
        <>
            <div style={headerStyle}>
                <h3>Cached Logging Areas</h3>
                <ButtonComponent isLoading={isUpdating} disabled={isUpdating}
                                 onClick={handleLoggingUpdate}
                                 style={{marginTop: "10px", width: "10%"}}>
                    Update
                </ButtonComponent>
            </div>
            <LoggingAreaTableComponent loggingData={loggingData} handleDropdownChange={handleLoggingDropdownChange}/>
        </>
    )
}
